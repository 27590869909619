<mat-toolbar>
  <p>
    &copy; 2018-21 Cornelia Schulz
    <a
      href="https://www.linkedin.com/in/cornelia-schulz"
      target="_blank"
      rel="noopener"
    >
      <img src="../../assets/images/linkedin.png" alt="linked in" loading="lazy" />
    </a> 
    <a
      href="https://github.com/cornelia-schulz"
      target="_blank"
      rel="noopener"
    >
      <img src="../../assets/images/github.png" alt="github" loading="lazy" />
    </a>
  </p>
</mat-toolbar>