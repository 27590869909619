<div id="banner" class="banner">
  <picture class="banner-image">
    <source 
      media="(min-width: 650px)"
      srcset="../../assets/images/mt-cook-national-park.jpg">
    <img 
      src="../../assets/images/opera-house.jpg" 
      alt="Sydney">
  </picture>
  <div class="title">
    <h1>{{title}}</h1>
  </div>
</div>