<div class="projects" id="projects">
  <h2>{{title}}</h2>
  <mat-grid-list [cols]="breakpoint" rowHeight="2:1" (window:resize)="onResize($event)">
    <mat-grid-tile id="tile1" (mouseenter)="showText('tile1')" (mouseleave)="hideText('tile1')"
      (click)="openDialog('1')">
      <p>
        <span class="title">Pie Reviews</span><br />
        <span class="description">A side project to look at some yummy pies</span><br />
        <span class="more-info">More info <img class="touch-app" src="../../assets/images/outline_touch_app_white_24dp.png" alt="touch icon" /></span>
      </p>
    </mat-grid-tile>
    <mat-grid-tile id="tile2" (mouseenter)="showText('tile2')" (mouseleave)="hideText('tile2')"
      (click)="openDialog('2')">
      <p>
        <span class="title">Lost in Translation</span><br />
        <span class="description">A personal blog about various topics</span><br />
        <span class="more-info">More info <img class="touch-app" src="../../assets/images/outline_touch_app_white_24dp.png" alt="touch icon" /></span>
      </p>
    </mat-grid-tile>
    <mat-grid-tile id="tile3" (mouseenter)="showText('tile3')" (mouseleave)="hideText('tile3')"
      (click)="openDialog('3')">
      <p>
        <span class="title">Photography Portfolio</span><br />
        <span class="description">A site to show off my aspirations as photographer</span><br />
        <span class="more-info">More info <img class="touch-app" src="../../assets/images/outline_touch_app_white_24dp.png" alt="touch icon" /></span>
      </p>
    </mat-grid-tile>
    <mat-grid-tile id="tile4" (mouseenter)="showText('tile4')" (mouseleave)="hideText('tile4')"
      (click)="openDialog('4')">
      <p>
        <span class="title">Photo Locations</span><br />
        <span class="description">Find and record your favourite photography spots</span><br />
        <span class="more-info">More info <img class="touch-app" src="../../assets/images/outline_touch_app_white_24dp.png" alt="touch icon" /></span>
      </p>
    </mat-grid-tile>
  </mat-grid-list>
</div>