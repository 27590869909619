<div class="contact" id="contact">
    <div class="links">
      <img class="profile-img" src="../../assets/images/profile.png" alt="profile picture" loading="lazy" />
      <div class="social-media">
        <a 
          href="https://www.linkedin.com/in/cornelia-schulz"
          target="_blank"
          rel="noopener"
        >
          <img class="linkedin" src="../../assets/images/transparent.gif" alt="linked in" loading="lazy" />
        </a>
        <a
          href="https://github.com/cornelia-schulz"
          target="_blank"
          rel="noopener"
        >
          <img class="github" src="../../assets/images/transparent.gif" alt="github" loading="lazy" />
        </a>
        <a
          href="mailto:firzhugh@gmail.com"
          target="_blank"
          rel="noopener"
        >
          <img class="email" src="../../assets/images/transparent.gif" alt="email" loading="lazy" />
        </a>
      </div>
    </div>
    <form #contactForm="ngForm" class="contact-me" (ngSubmit)="processForm()">
      <h2>Say hello!</h2>
      <mat-form-field>
        <input matInput placeholder="Name" type="text" name="name" [(ngModel)]="name" required>
      </mat-form-field>
      <mat-form-field>
        <input 
          required
          matInput placeholder="Email"
          type="text" name="email"
          [(ngModel)]="email"/>
      </mat-form-field>
      <mat-form-field>
        <textarea matInput placeholder="Message" 
        rows="5" name="message" [(ngModel)]="message"></textarea>
      </mat-form-field>
      <button type="submit" [disabled]="!contactForm.valid" (click)="messageSentSnackBar()">Send</button>
    </form>
    <picture id="map">
      <source
        media="(min-width: 650px)"
        srcset="../../assets/images/christchurch.PNG"
      >
      <img class="location-img" src="../../assets/images/christchurch-mobile.PNG" alt="Location Map" loading="lazy" />
    </picture>
  </div>