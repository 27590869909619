<h3 mat-dialog-title>{{data.name}}</h3>
<div mat-dialog-content>
  <img src={{data.image}} alt={{data.name}} />
  <p>{{data.description}}</p>
  <p>Technologies I used for this project:</p>
  <ul>
    <li *ngFor="let technology of data.technologies">{{technology}}</li>
  </ul>
  <p>Check the code out on <a href={{data.github}} target="_blank">Github</a>.
     <span *ngIf="data.website">Or go straight to the <a href={{data.website}} target="_blank">website</a>.</span></p>
</div>
<div mat-dialog-actions>
  <button id="close-dialog" mat-button (click)="onNoClick()" cdkFocusInitial>Close</button>
</div>